.Appcont {
    background-image: url("./../../assets//back.jpg");
    background-size: cover;
    display: flex;
    justify-content: space-around;
    align-items: center; 
    width: 100vw;
    height: 110vh;
    padding: 20px; 
    box-sizing: border-box; 
  }
  
.contact-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0.437); 
    backdrop-filter: 5px;
    border-radius: 15px;
    width: 500px;
    height: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 20px; 
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px; 
    font-size: 1.5rem;
  }
  
  .contact-item svg {
    font-size: 2rem; 
    margin-right: 10px; 
  }
  
  .map iframe {
  border: solid rgb(255, 255, 255) 10px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  
  }

  iframe{
    margin-bottom: 20px;
    width:600px;
    height: 450px; 
  }
  
  @media (max-width: 1200px) {
    .Appcont{
      padding-top: 100px;
      flex-direction: column;
      gap: 20px;
    }
    .map{
      padding-bottom: 20px;
    }
    .contact-info {
        width: 90%; /* Adjust width for smaller screens */
        padding: 10px; /* Adjust padding */
    }
  
    h1 {
        font-size: 1.2rem; /* Smaller font size for smaller screens */
        margin-bottom: 10px; /* Adjust margin */
    }
  
    .contact-item {
        margin-bottom: 8px; /* Adjust margin */
    }
  
    .contact-item svg {
        font-size: 1.2rem; /* Smaller icon size */
        margin-right: 6px; /* Adjust margin */
    }

    .map{
      width: 90%;
    }
    
    iframe{
      margin-bottom: 20px;
      width:100%;
      height: 450px; 
    }
  }
  