/* Header styling */
.header {
  background-color: #2c3e5084;
  backdrop-filter: blur(5px);
  color: white;
  padding: 20px;
  text-align: center;
  padding-top: 110px;
}

/* Container for documents */
.documentsContainer {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

/* Styling for document boxes (not used with links) */
.documentsContainer .documentBox {
  border-radius: 5px;
  padding: 15px;
  margin: 10px 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.58);
  backdrop-filter: blur(2px);
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

/* Hover effect for document boxes (not used with links) */
.documentsContainer .documentBox:hover {
  background-color: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(6px);
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Styling for links within documents container */
.documentsContainer a {
  color: #ffbf01; /* Adjust color as needed */
  text-decoration: none;
  font-size: 18px;
  display: block; /* Ensure links are block-level for proper spacing */
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5); /* Match background color of document boxes */
  transition: background-color 0.2s, transform 0.2s;
}

/* Hover effect for links */
.documentsContainer a:hover {
  background-color: rgba(0, 0, 0, 0.7);
  transform: scale(1.05);
}

/* Error styling (if needed) */
.error {
  color: white;
}

/* Modal styling (for reference if used in the future) */
.modal {
  position: absolute;
  top: 10%;
  left: 10%;
  right: 10%;
  bottom: 10%;
  background: white;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

/* Overlay styling (for reference if used in the future) */
.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Close button styling (for reference if used in the future) */
.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 24px;
  cursor: pointer;
}
