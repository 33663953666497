/* src/components/gallery.css */

.gallery-container {
    text-align: center;
    padding: 20px;
  }
  
  .gallery-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .gallery-image {
    width: 200px;
    height: auto;
    margin: 10px;
    object-fit: contain;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .gallery-image:hover {
    transform: scale(1.05);
  }
  
  .modal {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-image {
    max-width: 50%;
    max-height: 50%;
    border: 5px solid white;
  }
  
  .modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    color: white;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px;
    transition: background 0.3s ease;
    z-index: 1001; /* Ensure close button is above the image */
  }
  
  .modal-close:hover {
    background: rgba(0, 0, 0, 0.7);
  }
  