

.lepls {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .partniors li {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .partniors img {
    max-width: 100%;
    height: auto;
  }
  
  .section-title .pull-left {
    font-family: bpg_mrgvlovani_caps_2010;
    font-size: 20px;
    border-bottom: 6px solid #0e948f;
    float: left;
  }
  
  .partniors {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .section-title {
    border-bottom: 1px solid #d3d4d6;
    overflow: hidden;
    margin: 20px 0;
  }
  
  .partniors li:first-child {
    padding-left: 0;
  }
  
  .partniors li {
    float: left;
    padding: 0px;
    width: 20%;
  }
  
  .partniors li img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .section-6 .col-md-12 {
    padding-left: 0;
    padding-right: 0;
  }
  
  ul {
    list-style-type: none;
  }
  
  .partniors li {
    width: 200px;
    height: 83px;
    margin: 5px;
    background: #eee;
    padding: 13px 0;
  }
  
  