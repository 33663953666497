@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles */
body {
  box-sizing: border-box;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}